<template>
    <section class="nyukai-main">
        <v-container class="fill-height" fluid>
            <v-row class="nyukai-main__contents" v-if="!$store.state.isComplete">
                <v-col cols="12" sm="8" md="4" class="nyukai-main__contents-text">
                    <v-card flat color="transparent">


                        <!-- step1 ご利用情報入力 -->
                        <v-form v-model="valid" ref="customerForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light text-caption">お子様の名前</p>
                                        <v-row>
                                            <v-col cols="6" id="ruleForm-lastname">
                                                <!-- <p class="v-label theme--light">姓</p> -->
                                                <v-text-field v-model="ruleForm.lastname"
                                                              label="姓"
                                                              :rules="rules.lastname"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" id="ruleForm-firstname">
                                                <!-- <p class="v-label theme--light">名</p> -->
                                                <v-text-field v-model="ruleForm.firstname"
                                                              label="名"
                                                              :rules="rules.firstname"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="v-label theme--light text-caption">フリガナ</p>
                                        <v-row>
                                            <v-col cols="6" id="ruleForm-lastkana">
                                                <!-- <p class="v-label theme--light">姓</p> -->
                                                <v-text-field v-model="ruleForm.lastkana"
                                                              label="姓"
                                                              :rules="rules.lastkana"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" id="ruleForm-firstkana">
                                                <!-- <p class="v-label theme--light">名</p> -->
                                                <v-text-field v-model="ruleForm.firstkana"
                                                              label="名"
                                                              :rules="rules.firstkana"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="v-label theme--light text-caption">お子様の生年月日</p>
                                        <v-row>
                                            <v-col cols="6" id="ruleForm-birthyear">
                                                <!-- <p class="v-label theme--light">年</p> -->
                                                <v-select v-model="ruleForm.birthyear"
                                                          :items="year"
                                                          @change="changeBirthYearMonth()"
                                                          :rules="rules.birthyear"
                                                          label="年"></v-select>
                                            </v-col>
                                            <v-col cols="3" id="ruleForm-birthmonth">
                                                <!-- <p class="v-label theme--light">月</p> -->
                                                <v-select v-model="ruleForm.birthmonth"
                                                          :items="month"
                                                          @change="changeBirthYearMonth()"
                                                          :rules="rules.birthmonth"
                                                          label="月"></v-select>
                                            </v-col>
                                            <v-col cols="3" id="ruleForm-birthdate">
                                                <!-- <p class="v-label theme--light">日</p> -->
                                                <v-select v-model="ruleForm.birthdate"
                                                          :items="date"
                                                          @change="ruleForm.age = getAge(ruleForm.birthyear + '-' + ruleForm.birthmonth + '-' + ruleForm.birthdate)"
                                                          :rules="rules.birthdate"
                                                          label="日"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <!-- <p class="v-label theme--light">お子様の年齢</p> -->
                                        <v-text-field v-model="ruleForm.age"
                                                      label="お子様の年齢"
                                                      :readonly="true"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="v-label theme--light text-caption">保護者氏名</p>
                                        <v-row>
                                            <v-col cols="6" id="ruleForm-parentlastname">
                                                <!-- <p class="v-label theme--light">姓</p> -->
                                                <v-text-field v-model="ruleForm.parentlastname"
                                                              label="姓"
                                                              :rules="rules.parentlastname"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" id="ruleForm-parentfirstname">
                                                <!-- <p class="v-label theme--light">名</p> -->
                                                <v-text-field v-model="ruleForm.parentfirstname"
                                                              label="名"
                                                              :rules="rules.parentfirstname"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-tel">
                                        <!-- <p class="v-label theme--light">電話番号(ハイフン無し)</p> -->
                                        <v-text-field v-model="ruleForm.tel"
                                        label="電話番号(ハイフン無し)"
                                                      :rules="rules.tel"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-zip">
                                        <p class="v-label theme--light text-caption">住所</p>
                                        <!-- <p class="v-label theme--light">郵便番号(ハイフン無し)</p> -->
                                        <v-text-field v-model="ruleForm.zip"
                                                      label="郵便番号(ハイフン無し)"
                                                      :rules="rules.zip"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-region">
                                        <!-- <p class="v-label theme--light">都道府県</p> -->
                                        <v-text-field v-model="ruleForm.region"
                                        label="都道府県"
                                                      :rules="rules.region"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-locality">
                                        <!-- <p class="v-label theme--light">市町村</p> -->
                                        <v-text-field v-model="ruleForm.locality"
                                        label="市町村"
                                                      :rules="rules.locality"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-street">
                                        <!-- <p class="v-label theme--light">番地以下</p> -->
                                        <v-text-field v-model="ruleForm.street"
                                        label="番地以下"
                                                      :rules="rules.street"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-email">
                                        <!-- <p class="v-label theme--light">メールアドレス</p> -->
                                        <v-text-field v-model="ruleForm.email"
                                        label="メールアドレス"
                                                      :rules="rules.email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-schoolname">
                                        <!-- <p class="v-label theme--light">現在の学校名(任意)</p> -->
                                        <v-text-field v-model="ruleForm.schoolname"
                                        label="現在の学校名(任意)"
                                                      :rules="rules.schoolname"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-size">
                                        <!-- <p class="v-label theme--light">学年</p> -->
                                        <v-select v-model="ruleForm.grade"
                                                  :items="grades"
                                                  label="学年"></v-select>
                                    </v-col>

                                    <v-col cols="12" id="ruleForm-experience">
                                        <!-- <p class="v-label theme--light">スイミング経験</p> -->
                                        <v-radio-group v-model="ruleForm.experience" :rules="rules.experience" row label="スイミング経験">
                                            <v-radio label="あり" value="1"></v-radio>
                                            <v-radio label="なし" value="0"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12">
                                        <!-- <p class="v-label theme--light">泳力</p> -->
                                        <v-select v-model="ruleForm.swimpowercheck"
                                                  :items="swimpowers"
                                                  label="泳力"
                                                  :rules="rules.swimpowercheck"
                                                  multiple
                                                  chips></v-select>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-seibetu">
                                        <!-- <p class="v-label theme--light">性別</p> -->
                                        <v-radio-group v-model="ruleForm.seibetu" @change="$emit('selectSex')" :rules="rules.seibetu" row label="性別">
                                            <v-radio label="男性" value="男"></v-radio>
                                            <v-radio label="女性" value="女"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-contactableHour" v-if="isSelectedTrial">
                                        <p class="v-label theme--light">連絡可能時間帯</p>
                                        <v-select v-model="ruleForm.contactableHour"
                                                  :items="contactableHours.filter(r => r.storeID == storeId)"
                                                  item-text="name"
                                                  item-value="ID"
                                                  :rules="rules.contactableHour"
                                                  label="選択してください"></v-select>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-size" v-if="isSelectedJoin">
                                        <!-- <p class="v-label theme--light">水着サイズ</p> -->
                                        <v-select v-model="ruleForm.size"
                                                  :items="sizes"
                                                  item-text="itemName"
                                                  item-value="itemCD"
                                                  :rules="rules.size"
                                                  label="水着サイズ"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="text-align:center;">
                                        <v-btn depressed @click="moveNextForm('confirm')" style="margin-right:5%;" color="primary" v-if="isSelectedJoin">利用規約を確認</v-btn>
                                        <v-btn depressed @click="moveNextForm('payment')" style="margin-right:5%;" color="primary" v-if="!isSelectedJoin">合計金額確認</v-btn>
                                        <v-btn depressed @click="returnForm" color="warning">戻る</v-btn>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>

                    </v-card>
                </v-col>

            </v-row>
        </v-container>

    </section>
</template>

<script>

    export default {

        components: {
        },

        model: {
            prop: 'ruleForm',
            event: 'changeFormValue'
        },
        props: {
            ruleForm: Object,
            sizes: Array,
            contactableHours: Array,
            isSelectedJoin: Boolean,
            isSelectedTrial: Boolean,
            isSelectedTwiceAWeek: Boolean,
            storeId: Number,
        },
        data() {
            return {
                //バリデーションに引っかかった時にヘッダーの分も上にしないと見えないため(createdで高さ取得)
                headerHeight: 0,
                valid: true,
                rules: {
                    lastname: [
                        v => { return this.blankRules(v, 0) },
                        v => { return this.nameRules(v, 0, 1) },
                    ],
                    firstname: [
                        v => { return this.blankRules(v, 1) },
                        v => { return this.nameRules(v, 1, 1) },
                    ],
                    lastkana: [
                        v => { return this.blankRules(v, 2) },
                        v => { return this.nameRules(v, 2, 2) },
                    ],
                    firstkana: [
                        v => { return this.blankRules(v, 3) },
                        v => { return this.nameRules(v, 3, 2) },
                    ],
                    seibetu: [v => { return this.blankRules(v, 4) },],
                    birthyear: [v => { return this.blankRules(v, 5) },],
                    birthmonth: [v => { return this.blankRules(v, 6) },],
                    birthdate: [v => { return this.blankRules(v, 7) },],
                    parentlastname: [
                        v => { return this.blankRules(v, 8) },
                        v => { return this.nameRules(v, 8, 3) },
                    ],
                    parentfirstname: [
                        v => { return this.blankRules(v, 9) },
                        v => { return this.nameRules(v, 9, 3) },
                    ],
                    tel: [
                        v => { return this.blankRules(v, 10) },
                        v => { return this.telRules(v, 10) },
                    ],
                    zip: [
                        v => { return this.blankRules(v, 11) },
                        v => { return this.zipRules(v, 11) },
                    ],
                    region: [v => { return this.blankRules(v, 12) },],
                    locality: [v => { return this.blankRules(v, 13) },],
                    street: [v => { return this.blankRules(v, 14) },],
                    email: [
                        //v => { return this.blankRules(v, 15) },
                        v => { return this.emailRules(v, 15) },
                    ],
                    schoolname: [v => { return this.limitRules(v, 16, 20) },],
                    swimpowercheck: [v => { return this.arrayRules(v, 17) },],
                    contactableHour: [v => { return this.blankRules(v, 18) },],
                    size: [v => { return this.blankRules(v, 18) },],

                },
                //全体をバリデーションした時にエラーが起こった一番上の項目へ飛ばすためのオブジェクト
                validationResult: [
                    { id: "ruleForm-lastname", result: true },
                    { id: "ruleForm-firstname", result: true },
                    { id: "ruleForm-lastkana", result: true },
                    { id: "ruleForm-firstkana", result: true },
                    { id: "ruleForm-seibetu", result: true },
                    { id: "ruleForm-birthyear", result: true },
                    { id: "ruleForm-birthmonth", result: true },
                    { id: "ruleForm-birthdate", result: true },
                    { id: "ruleForm-parentlastname", result: true },
                    { id: "ruleForm-parentfirstname", result: true },
                    { id: "ruleForm-tel", result: true },
                    { id: "ruleForm-zip", result: true },
                    { id: "ruleForm-region", result: true },
                    { id: "ruleForm-locality", result: true },
                    { id: "ruleForm-street", result: true },
                    { id: "ruleForm-email", result: true },
                    { id: "ruleForm-schoolname", result: true },
                    { id: "ruleForm-swimpowercheck", result: true },
                    { id: "ruleForm-contactableHour", result: true },
                    { id: "ruleForm-size", result: true },
                ],
                //validate: [],
                blankRules: function (v, index) { return !!v || this.changeValidationResult(index) || '必須です' },
                limitRules: function (v, index, number) { return v.length <= number || this.changeValidationResult(index) || String(number) + '文字以内に収めてください' },
                nameRules: function (v, index, kind) {
                    if (kind == 1) {
                        //子供の名前
                        return this.ruleForm.lastname.length + this.ruleForm.firstname.length <= 20 || this.changeValidationResult(index) || '姓名合わせて20文字以内に収めてください'

                    } else if (kind == 2) {
                        //子供の名前(カナ)
                        return this.ruleForm.lastkana.length + this.ruleForm.firstkana.length <= 20 || this.changeValidationResult(index) || '姓名合わせて20文字以内に収めてください'

                    } else if (kind == 3) {
                        //保護者の名前
                        return this.ruleForm.parentlastname.length + this.ruleForm.parentfirstname.length <= 20 || this.changeValidationResult(index) || '姓名合わせて20文字以内に収めてください'

                    }
                },
                telRules: function (v, index) { return /^0\d{9,10}$/.test(v) || this.changeValidationResult(index) || '正しい形式で入力してください。例）1234567890' },
                zipRules: function (v, index) { return /^\d{7}$/.test(v) || this.changeValidationResult(index) || '正しい形式で入力してください。例）1112222' },
                emailRules: function (v, index) { return /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(v) || this.changeValidationResult(index) || '正しい形式で入力してください。' },
                arrayRules: function (v, index) { return v.length != 0 || this.changeValidationResult(index) || 'どれかを選択してください' },

                year: [],
                month: [
                    { value: '01', text: '1' },
                    { value: '02', text: '2' },
                    { value: '03', text: '3' },
                    { value: '04', text: '4' },
                    { value: '05', text: '5' },
                    { value: '06', text: '6' },
                    { value: '07', text: '7' },
                    { value: '08', text: '8' },
                    { value: '09', text: '9' },
                    { value: '10', text: '10' },
                    { value: '11', text: '11' },
                    { value: '12', text: '12' },
                ],
                date: [],
                //年月の入力に合わせてdateにコピー
                dateArray: [
                    { value: '01', text: '1', }, { value: '02', text: '2', }, { value: '03', text: '3', }, { value: '04', text: '4', },
                    { value: '05', text: '5', }, { value: '06', text: '6', }, { value: '07', text: '7', }, { value: '08', text: '8', },
                    { value: '09', text: '9', }, { value: '10', text: '10', }, { value: '11', text: '11', }, { value: '12', text: '12', },
                    { value: '13', text: '13', }, { value: '14', text: '14', }, { value: '15', text: '15', }, { value: '16', text: '16', },
                    { value: '17', text: '17', }, { value: '18', text: '18', }, { value: '19', text: '19', }, { value: '20', text: '20', },
                    { value: '21', text: '21', }, { value: '22', text: '22', }, { value: '23', text: '23', }, { value: '24', text: '24', },
                    { value: '25', text: '25', }, { value: '26', text: '26', }, { value: '27', text: '27', }, { value: '28', text: '28', },
                    { value: '29', text: '29', }, { value: '30', text: '30', }, { value: '31', text: '31', }
                ],
                swimpowers: [
                    '水を怖がる',
                    '顔つけができる',
                    '水にもぐれる',
                    'けのびができる',
                    '板キックができる',
                    'クロールが泳げる',
                    'クロール以外も泳げる',
                ],
                grades: [
                    { value: '未就学児', text: '未就学児' },
                    { value: '年少', text: '年少' },
                    { value: '年中', text: '年中' },
                    { value: '年長', text: '年長' },

                    { value: '小学1年生', text: '小学1年生' },
                    { value: '小学2年生', text: '小学2年生' },
                    { value: '小学3年生', text: '小学3年生' },
                    { value: '小学4年生', text: '小学4年生' },
                    { value: '小学5年生', text: '小学5年生' },
                    { value: '小学6年生', text: '小学6年生' },

                    { value: '中学1年生', text: '中学1年生' },
                    { value: '中学2年生', text: '中学2年生' },
                    { value: '中学3年生', text: '中学3年生' },
                ],


            }
        },

        methods: {
            applyNewly: function () {
                window.open(this.$store.state.projectStarturl);
            },
            changeBirthYearMonth: function () {
                //年月の入力があるかどうか
                var isVacantYearAndMonth = this.ruleForm.birthyear == "" || this.ruleForm.birthmonth == "";
                this.$set(this, "date", []);
                var last_day;
                if (!isVacantYearAndMonth) {
                    for (var i = 0; i < this.dateArray.length; i++) {
                        last_day = new Date(this.ruleForm.birthyear, this.ruleForm.birthmonth, 0).getDate()
                        //最終日よりも小さい場合はpush
                        if (last_day >= Number(this.dateArray[i].value)) {
                            this.date.push(this.dateArray[i]);
                        }
                    }
                } 
                //もともと日付が選択されていた場合、変更されて日付が無くなったら消す
                if (this.ruleForm.birthdate != "") {
                    if (last_day != undefined && last_day < this.ruleForm.birthdate) {
                        this.ruleForm.birthdate = "";
                        this.ruleForm.age = "";
                    }
                }
                if (!isVacantYearAndMonth && this.ruleForm.birthdate != "") {
                    this.ruleForm.age = this.getAge(this.ruleForm.birthyear + "-" + this.ruleForm.birthmonth + "-" + this.ruleForm.birthdate);

                }
            },
            getAge(birthday) {

                if (birthday == "") {
                    return "";
                }
                var today = this.$moment.utc().add(9, "H").format('YYYYMMDD');
                birthday = this.$moment(birthday).format('YYYYMMDD');

                if (today - birthday < 0) {
                    return 0;
                }
                return (Math.floor((today - birthday) / 10000));
            },
            //全体をバリデーションした時にどこがバリデーションクリアしないかを区別させる関数:validationResultにfalseを入れる
            changeValidationResult: function (index) {
                this.validationResult[index].result = false;
                //vuetifyのruleの仕様上return falseをしてその右の文字列まで評価させる必要がある
                return false;
            },
            //バリデーション通過しなかった時にその中でどの項目が一番上か、idを取ってくる処理
            getFalseValidationID: function () {
                var targetIndex = this.validationResult.length - 1;
                //配列の末尾から見て一番先頭の所をターゲットにする
                for (var i = this.validationResult.length - 1; i >= 0; i--) {
                    if (!this.validationResult[i].result) targetIndex = i;
                }
                return this.validationResult[targetIndex].id;
            },
            resetValidationResult: function () {
                //一度全部trueにする
                for (var i in this.validationResult) {
                    this.validationResult[i].result = true;
                }
            },
            moveNextForm: function (pageName) {

                this.resetValidationResult();

                if (this.$refs.customerForm.validate()) {

                    this.$emit('moveForm', pageName);


                } else {
                    alert("未入力の項目があります");

                    //#で場所に移動し、ヘッダーの分だけ下に下げる
                    //window.location.href = '#' + this.getFalseValidationID();
                    var element = document.getElementById(this.getFalseValidationID())
                    var scroll = window.pageYOffset + (element.getBoundingClientRect().top - element.clientHeight)
                    window.scrollTo(0, scroll);
                }

            },
            returnForm: function () {
                this.$emit('moveForm', "reservation");
            },

        },

        created() {
  
            var nowYear = new Date().getFullYear();

            //とりあえず30年分だけ取ってくる
            for (var i = 0; i < 30; i++) {
                this.year.unshift({ value: String(nowYear - i), text: String(nowYear - i) });
            }
            this.changeBirthYearMonth();
  
        },
        mounted: function () {

            this.headerHeight = document.getElementById("RenaissHeader").clientHeight;
            window.scrollTo(0, 0);


        },
        watch: {
            ruleForm: {
                handler: function () {
                    //親の値と同期をする
                    this.$emit('changeFormValue', this.ruleForm);
                },
                deep: true,
            },
            'ruleForm.zip': function (zip) {
                const reg = /^[0-9]{3}[-][0-9]{4}$/ //ハイフンあり
                const reg2 = /^\d{7}$/              //ハイフンなし

                if (!reg.test(zip) && !reg2.test(zip)) {
                    return
                }
                let _this = this;
                new window.YubinBango.Core(zip, function (addr) {

                    _this.ruleForm.region = addr.region
                    _this.ruleForm.locality = addr.locality + addr.street
                })
            },

        },


    }
</script>

<style lang="scss" scoped>
    .usedetail_text {
        text-align: left;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 0;
    }

    .nyukai-main__contents {
        justify-content: center;
    }

    .nyukai-radio {
        margin-top: 10px;

        @media only screen and (min-width:651px) {
            margin-top: 0px;
        }

        @media only screen and (min-width:961px) {
            margin-top: 0px;
        }
    }

    .confirm_checkbox {
        margin-bottom: 3%;
    }
</style>
