<template>
    <section class="nyukai-main">
        <v-container class="fill-height" fluid>
            <v-row class="nyukai-main__contents"  v-if="!$store.state.isComplete">
                <v-col cols="12" sm="8" md="4" class="nyukai-main__contents-text">
                    <v-card flat color="transparent">

                        <!-- step1 ご利用情報入力 -->
                        <v-form v-model="valid" ref="reservationForm">
                            <v-container>

                                <v-row>
                                    <v-col cols="12" id="ruleForm-selectLabel">
                                        <p class="v-label theme--light">1日体験・入会・体験&入会申込内容選択</p>
                                        <p class="v-label theme--light text-caption">1日体験・入会・体験&入会をご希望の方は下記を選択し、お客様情報入力にお進みください。</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-select">
                                        <v-radio-group :rules="rules.select" v-model="ruleForm.select" @change="$emit('selectContent', ruleForm.select)" row>
                                            <v-radio label="体験教室" value="1"></v-radio>
                                            <v-radio label="入会申込" value="2"></v-radio>
                                            <v-radio label="体験教室＆入会申込" value="3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" id="ruleForm-invitationCodeLabel" v-if="isSelectedJoin">
                                        <p class="v-label theme--light text-caption">
                                            紹介コードをお持ちの方は入力してください。<br>
                                            (お持ちでない場合は、入力せずにそのままお進みください)
                                        </p>
                                    </v-col>
                                    <v-col cols="7" id="ruleForm-invitationCode" v-if="isSelectedJoin">
                                        <!-- <p class="v-label theme--light">紹介コード</p> -->

                                        <v-text-field label="紹介コード" type="number" v-model="ruleForm.invitationCode" :disabled="isCodeVerified"></v-text-field><!--@change="selectInvitationCodeAndCoupon()"-->
                                    </v-col>

                                    <v-col cols="2" id="ruleForm-invitationCodeBtn" v-if="isSelectedJoin">
                                        <v-btn depressed color="primary" style="vertical-align:middle;" @click="selectInvitationCodeAndCoupon()" v-if="!isCodeVerified">認証</v-btn>
                                        <v-icon large color="green darken-1" v-if="isCodeVerified">mdi-check-circle</v-icon>
                                    </v-col>

                                    <v-col cols="12" id="ruleForm-invitationCodeMessage" v-if="isSelectedJoin && !!ruleForm.coupon">
                                        {{ ruleForm.coupon.messageForMember }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" id="ruleForm-store">
                                        <!-- <p class="v-label theme--light">ご利用店舗選択</p> -->
                                        <v-select v-model="ruleForm.store"
                                                  :items="stores"
                                                  @change="$emit('selectStore', ruleForm.store), selectDiscountValue()"
                                                  item-text="storeName"
                                                  item-value="storeID"
                                                  :rules="rules.store"
                                                  label="ご利用店舗選択"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" id="ruleForm-trial" v-if="isSelectedTrial">
                                        <!-- <p class="v-label theme--light">体験日付</p> -->
                                        <v-select v-model="ruleForm.trial"
                                                  :items="trials"
                                                  item-text="datetimeStartFormat"
                                                  item-value="stockDataID"
                                                  :rules="rules.trial"
                                                  label="体験日付"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" id="ruleForm-selectweek" v-if="isSelectedJoin">
                                        <!-- <p class="v-label theme--light">レッスンコース選択</p> -->
                                        <v-radio-group label="レッスンコース選択" v-model="ruleForm.selectweek" @change="$emit('selectTwiceAWeek', ruleForm.selectweek)" :rules="rules.selectweek" row>
                                            <v-radio label="週一" value="1"></v-radio>
                                            <v-radio label="週二" value="2"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>

                                <v-row v-if="isSelectedJoin">
                                    <v-col cols="7" id="ruleForm-week1">
                                        <!-- <p class="v-label theme--light">希望曜日時間1</p> -->
                                        <v-select v-model="ruleForm.week1"
                                                  :items="weeks"
                                                  item-text="datetimeStartFormat"
                                                  item-value="ID"
                                                  :rules="rules.week1"
                                                  label="希望曜日時間1"></v-select>
                                    </v-col>
                                    <v-col cols="5" id="ruleForm-bus1">
                                        <!-- <p class="v-label theme--light">バス利用1</p> -->
                                        <v-select v-model="ruleForm.bus1"
                                                  :items="busSelectOption"
                                                  :rules="rules.bus1"
                                                  label="バス利用1"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isSelectedJoin && isSelectedTwiceAWeek">
                                    <v-col cols="7" id="ruleForm-week2">
                                        <!-- <p class="v-label theme--light">希望曜日時間2</p> -->
                                        <v-select v-model="ruleForm.week2"
                                                  :items="weeks"
                                                  item-text="datetimeStartFormat"
                                                  item-value="ID"
                                                  :rules="rules.week2"
                                                  label="希望曜日時間2"></v-select>
                                    </v-col>
                                    <v-col cols="5" id="ruleForm-bus2">
                                        <!-- <p class="v-label theme--light">バス利用2</p> -->
                                        <v-select v-model="ruleForm.bus2"
                                                  :items="busSelectOption"
                                                  :rules="rules.bus2"
                                                  label="バス利用2"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="7" id="ruleForm-visit" v-if="isSelectedJoin">
                                        <!-- <p class="v-label theme--light">用品受取日 ※最終手続があります </p> -->
                                        <v-select v-model="ruleForm.visit"
                                                  :items="visits"
                                                  item-text="datetimeStartFormat"
                                                  item-value="ID"
                                                  :rules="rules.visit"
                                                  label="用品受取日"></v-select>
                                    </v-col>
                                    <v-col cols="5" id="ruleForm-visitCaution" v-if="isSelectedJoin">
                                        ※最終手続があります
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="text-align:center;">
                                        <v-btn depressed
                                               @click="moveNextForm"
                                               color="primary">
                                            お客様情報入力へ
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <!--1ヶ月体験は7&8月のみ表示-->
                                <!--<v-divider style="margin: 3em 0;"></v-divider>

                                <v-card outlined width="600">
                                    <v-card-text>
                                        <p class="v-label theme--light">1ヶ月体験</p>
                                        <p class="v-label theme--light text-caption">「1ヶ月体験」もございます。各リンク先よりお申し込みください。</p>
                                        <v-row style="margin: 0.5em 0;">
                                            <v-col cols="1">
                                            </v-col>
                                            <v-col cols="3">
                                                <v-btn style="width:100%;" outlined color="primary" href="https://scr.buscatch.net/application/?f=renaiss-echizen&i=63698">
                                                    越前校
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-btn style="width:100%;" outlined color="primary" href="https://scr.buscatch.net/application/?f=renaiss-sabae&i=64105">
                                                    鯖江校
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn style="width:100%;" outlined color="primary" href="https://scr.buscatch.net/application/?f=renaiss-fukuinishi&i=63555">
                                                    福井西校
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1">
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <v-card outlined width="600">
                                    <v-card-text>
                                        <p class="v-label theme--light">ステップアップコース</p>
                                        <p class="v-label theme--light text-caption">「ステップアップコース」もございます。各リンク先よりお申し込みください。</p>
                                        <v-row style="margin: 0.5em 0;">
                                            <v-col cols="2">
                                            </v-col>
                                            <v-col cols="8">
                                                <v-btn style="width:100%;" outlined color="primary" href="https://scr.buscatch.net/application/?f=renaiss-fukuinishi&i=62942">
                                                    ステップアップコース
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>-->
                            </v-container>
                        </v-form>

                    </v-card>
                </v-col>

            </v-row>
        </v-container>

    </section>
</template>

<script>

    export default {

        components: {
        },
        model: {
            prop: 'ruleForm',
            event: 'changeFormValue'
        },
        props: {
            ruleForm: Object,
            stores: Array,
            weeks: Array,
            trials: Array,
            visits: Array,
            isSelectedJoin: Boolean,
            isSelectedTrial: Boolean,
            isSelectedTwiceAWeek: Boolean,
        },

        data() {
            return {
                //バリデーションに引っかかった時にヘッダーの分も上にしないと見えないため(createdで高さ取得)
                headerHeight: 0,
                valid: true,
                busSelectOption: [
                    { text: "バス使用無し", value: "-1" },
                    { text: "行き", value: "0" },
                    { text: "帰り", value: "1" },
                    { text: "往復", value: "2" },
                ],
                rules: {
                    select: [v => { return this.blankRules(v, 0) },],
                    store: [v => { return this.blankRules(v, 1) },],
                    trial: [v => { return this.blankRules(v, 2) },],
                    selectweek: [v => { return this.blankRules(v, 3) },],
                    week1: [v => { return this.blankRules(v, 4) },],
                    bus1: [v => { return this.blankRules(v, 5) },],
                    week2: [v => { return this.blankRules(v, 6) },],
                    bus2: [v => { return this.blankRules(v, 7) },],
                    visit: [v => { return this.blankRules(v, 8) },],
                },
                //全体をバリデーションした時にエラーが起こった一番上の項目へ飛ばすためのオブジェクト
                validationResult: [
                    { id: "ruleForm-select", result: true },
                    { id: "ruleForm-store", result: true },
                    { id: "ruleForm-trial", result: true },
                    { id: "ruleForm-selectweek", result: true },
                    { id: "ruleForm-week1", result: true },
                    { id: "ruleForm-bus1", result: true },
                    { id: "ruleForm-week2", result: true },
                    { id: "ruleForm-bus2", result: true },
                    { id: "ruleForm-visit", result: true },
                ],
                //validate: [],
                blankRules: function (v, index) { return !!v || this.changeValidationResult(index) || '必須です' },

                //invitationCode: null,

                //coupon: null,//{
                    //couponID: null,
                    //inviterMemberCD: null,
                    //discountValue: null,
                    //invitationCode: null,
                    //freeBag: null,
                    freeCap: null,
                    //freeSuit: null,              
                //},

                //discountValue: null,

                isCodeVerified: false,                 

            }
        },

        methods: {
            //全体をバリデーションした時にどこがバリデーションクリアしないかを区別させる関数:validationResultにfalseを入れる
            changeValidationResult: function (index) {
                this.validationResult[index].result = false;
                //vuetifyのruleの仕様上return falseをしてその右の文字列まで評価させる必要がある
                return false;
            },
            //バリデーション通過しなかった時にその中でどの項目が一番上か、idを取ってくる処理
            getFalseValidationID: function () {
                var targetIndex = this.validationResult.length - 1;
                //配列の末尾から見て一番先頭の所をターゲットにする
                for (var i = this.validationResult.length - 1; i >= 0; i--) {
                    if (!this.validationResult[i].result) targetIndex = i;
                }
                return this.validationResult[targetIndex].id;
            },
            resetValidationResult: function () {
                //一度全部trueにする
                for (var i in this.validationResult) {
                    this.validationResult[i].result = true;
                }
            },
            moveNextForm: function () {

                this.resetValidationResult();

                if (this.$refs.reservationForm.validate()) {
                    this.$emit('moveForm', "customer");
                    
                } else {

                    alert("未入力の項目があります");

                    //#で場所に移動し、ヘッダーの分だけ下に下げる
                    //window.location.href = '#' + this.getFalseValidationID();
                    var element = document.getElementById(this.getFalseValidationID())
                    var scroll = window.pageYOffset + (element.getBoundingClientRect().top - element.clientHeight)
                    window.scrollTo(0, scroll);
                }

            },

            //クーポン情報取得
            selectInvitationCodeAndCoupon() {
                
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postInvitationCodeAndCoupon", { invitationCode: this.ruleForm.invitationCode })
                    .then(response => {

                        console.log('coupon is ');
                        console.log(response.data);
                        //alert(response.data);

                        if(response.data == null) {
                            //this.$set(this, "snackbarText", "有効なコードではありません");
                            //this.$set(this, "snackbar", true);

                            alert("有効なコードではありません");

                            return;
                        }        

                        //取ってきたデータを格納                        
                        this.$set(this.ruleForm, "coupon", response.data);
                        
                        if(this.ruleForm.coupon != null) {
                            this.setCoupon();

                            alert("コードの認証が完了しました");

                            this.$set(this, "isCodeVerified", true);

                            //this.$set(this, "snackbarText", "コードの認証が完了しました");
                            //this.$set(this, "snackbar", true);
                        }
                        else {
                            //this.$set(this, "snackbarText", "有効なコードではありません");
                            //this.$set(this, "snackbar", true);

                            alert("有効なコードではありません");

                            return;
                        }                        

                    })
                    .catch(response => {
                        console.log(response);

                        alert("認証に失敗しました");                        
                        //this.$set(this, "snackbarText", "コードの認証に失敗しました");
                        //this.$set(this, "snackbar", true);
                    })
            },

            //割引情報取得
            selectDiscountValue() {
                
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postDiscountValue", { storeID: this.ruleForm.store })
                    .then(response => {

                        console.log('discountValue is ');
                        console.log(response.data);

                        if (response.data != null && this.ruleForm.coupon != null && this.ruleForm.coupon.discountFlag == 1)
                        {
                            //取ってきたデータを格納                        
                            this.$set(this.ruleForm, "discountValue", response.data.discountValue);
                        }
                        else
                        {
                            //割引データがない、クーポンがない、割引が有効でない場合は0を格納
                            this.$set(this.ruleForm, "discountValue", 0);
                        } 

                        console.log('this.discountValue is ');
                        console.log(this.ruleForm.discountValue); 

                        this.setDiscountValue();

                        console.log("this.$store.state.discountValue is"); 
                        console.log(this.$store.state.discountValue); 
                        console.log("this.$store.state.coupon is");
                        console.log(this.$store.state.coupon);

                    })
                    .catch(response => {
                        console.log(response);                        
                        
                    })
            },

            //Vuex mutation クーポン
   	        setCoupon : function(){
  		        this.$store.commit('vuexSetCoupon', this.ruleForm.coupon);
  	        },     

            //Vuex mutation 割引
   	        setDiscountValue : function(){
  		        this.$store.commit('vuexSetDiscountValue', this.ruleForm.discountValue);
  	        }, 


            //紹介コードurlパラメータ取得&フォームにセット 
            getParamFromUrlAndSetOnForm: function(){
                var param = new URLSearchParams(window.location.search).get('invitationCode');

                if (param != null) {
                    this.$set(this.ruleForm, "invitationCode", param);
                    //this.selectInvitationCodeAndCoupon();
                }    
            },

            //店舗受付urlパラメータ取得 
            getParamFromUrlInStore: function(){
                var param = new URLSearchParams(window.location.search).get('instore');

                //店舗受付→登録区分=1
                if (param != null && param == 'true') {
                    this.$store.commit('vuexSetRegiDiv', 1);                 
                }    
            },
  
        },

        

        created() {

         

        },
        mounted: function () {
            this.headerHeight = document.getElementById("RenaissHeader").clientHeight;
            window.scrollTo(0, 0);

            this.getParamFromUrlAndSetOnForm();
        },
        watch: {
            ruleForm: {
                handler: function () {
                    //親の値と同期をする
                    this.$emit('changeFormValue', this.ruleForm);
                },
                deep:true,
            }
        }
   

    }
</script>

<style lang="scss" scoped>
    .usedetail_text {
        text-align: left;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 0;
    }

    .nyukai-main__contents {
        justify-content: center;
    }

    .nyukai-radio {
        margin-top: 10px;

        @media only screen and (min-width:651px) {
            margin-top: 0px;
        }

        @media only screen and (min-width:961px) {
            margin-top: 0px;
        }
    }

    .confirm_checkbox {
        margin-bottom: 3%;
    }
</style>
