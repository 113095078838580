﻿// JavaScript source code
export default {
    confirm1: "入会されるお子様は、3歳以上です。",
    confirm2: "入会されるお子様は、1人で着替えができます。",
    confirm3: "入会されるお子様は、オムツがとれていて1人でトイレができます。",
    confirm4: "入会されるお子様は、スタッフの指示に従って集団行動ができます。",
    confirm5: "入会されるお子様は、医師より運動を禁止されていません。",
    confirm6: "インフルエンザ、プール熱などの感染症やいぼ、とびひなどの伝染性疾患と思われる症状が認められた場合、当クラブの判断によりプールへの入水を一時的に禁止する場合があります。",
    confirm7: " 一旦納入された入会諸費用及び月会費は返還されません。",
    confirm8: "月会費は、毎月25日(休日の場合は翌営業日)に翌月分を口座振替により支払います。",
    confirm9: "年会費は、毎年1回入会月に月会費とあわせて支払います。",
    confirm10: "利用の有無に関わらず、退会の手続きが完了するまで会費の支払い義務が発生します。",
    confirm11: "クラブを退会される場合は、退会希望月の前月10日までにフロントでお手続きが必要となります。※電話、FAX、郵送、Eメール及びインターネットでの受付は行っておりません。",
    confirm12: "レッスンは、当クラブのレッスンカレンダーに基づき行われます。",
    confirm13: "レッスン受講の際は、当クラブ指定用品をご利用いただきます。指定用品の紛失、汚損等の場合は、実費にて再度購入していただきます。",
    confirm14: "当クラブ会員は、入会と同時に「ルネッスキッズ安心システム」への登録が必要となります。",
    confirm15: "欠席や振替の連絡は、所定の時間までに「ルネッスキッズ安心システム」から連絡します。",
    confirm16: "バスをご利用の方で、その日だけバスを利用されない場合、所定の時間までに「ルネッスキッズ安心システム」からバス不要の手続きを行います。",
    confirm17_1: "私は、当クラブの安全かつ円滑な運営のため、下記の事項を遵守します。",
    confirm17_2: "1.お子様の持ち物には、すべてはっきりと名前を書きます。",
    confirm17_3: "2.名前のない(名前の読み取れない)忘れ物は、2週間保管の後処分されます。",
    confirm17_4: "3.貴重品やゲーム機などレッスンに不要なものは持ち込みません。",
    confirm17_5: "4.レッスンの様子を撮影した場合、ソーシャルネットワーキングサービスなどの利用については、節度をもった利用を心がけます。",
    confirm17_6: "5.万一盗難、紛失などが生じた場合、私の責任において解決し、異議を述べません。",
    confirm18: "当クラブの運営に伴い、知り得た個人情報については、厳正に管理します。その上で、当クラブの掲示板等に、会員名簿・進級表・タイム結果・当クラブ会報を通じて名前や写真を掲示します。",
    confirm19: "私は、車で来た際は、必ず指定された駐車場に駐車します。",
    confirm20: "私は、万一駐車場における事故・紛失・盗難等のトラブルが生じた場合、私の責任において解決し、異議を述べません。",
    confirm21: "私は、未成年である会員の保護者として、上記項目を確認致しました。",
    terms: "同意しました。",


    termsDocument:`ルネッスキッズスイミングクラブ規約
第1条（定義）

本規約によって定める条項は、「ルネッスキッズスイミングクラブ」（以下「当クラブ」といいます）に適用されるものとします。

第2条（運営管理）

当クラブの運営管理は、株式会社エル・ローズ（以下「会社」といいます）が行います。

第3条（主旨）

当クラブは、会員がスイミングを通じて健康維持、増進および技術向上を図るとともに、会員相互の親睦を図り、健全なスイミングクラブとすることを目的とします。

第4条（会員制度）

当クラブは会員制とします。

会員種別、利用条件および特典などは別に定めます。但し、これらは会社が必要と判断した場合に改廃されることがあります。

第5条（入会資格・条件）

入会資格は、当クラブの主旨に賛同し、次の各号に該当する方で当クラブが認めた方とします。

3歳以上であること

1人で着替えができること

オムツがとれていて1人でトイレができること

スタッフの指示に従って集団行動ができること

医師より運動を禁止されていないこと

上記に反すると認められた場合、入会をお断りする場合があります。

第6条（入会手続）

当クラブに入会しようとする方は、本規約を承認した上で所定の手続を行い、会社の承認を得て入会金、会費および諸費用を納入することにより会員資格を取得し、入会できるものとします。

入会手続の方法については別に定めます。但し、これらは会社が必要と判断した場合に改訂されることがあります。

第7条（入会金、会費および諸費用）

会員は、会社が定めた入会金、会費および諸費用を所定の方法で、所定の期日までに納入しなければなりません。

一旦納入された入会金、会費および諸費用は、原則として理由のいかんを問わず返還しません。

入会金、会費および諸費用の詳細・内訳については別に定めます。但し、これらは会社が必要と判断した場合、または経済情勢等の変動に応じて改訂されることがあります。

第8条（会員証）

当クラブは、会員に対し会員証を発行します。

当クラブに入館および退館の際は、必ず会員証を提示しなければなりません。

会員証は本人のみが使用することができ、本人以外の方は使用できません。

会員証を紛失した場合は、速やかに所定の再発行手続を行わなければなりません。再発行手続に伴う費用は別に定めます。

会員資格を喪失した場合は、速やかに会員証を当クラブに返還しなければなりません。

第9条（変更事項）

会員は住所、連絡先、その他入会申込書記載内容に変更が生じた場合、速やかに当クラブへ届け出なければなりません。

当クラブは、会員への通知が必要な場合、会員から届出のあった最新の住所に通知を行い、以後の責任を負わないものとします。

第10条（休会）

休会を希望する方は、休会希望月の前月10日までに所定の手続を行い、会社の承認を得て、その月の1日付で休会するものとします。

休会中は別に定める休会費用を支払うものとします。

休会期間が満了した場合は自動的に復会します。その際、特段の通知等はいたしません。

休会を延長したい場合は再度所定の手続を行っていただきます。

第11条（資格喪失）

会員は、次の各号に該当した場合、会員資格を喪失します。

退会した場合

除名となった場合

死亡した場合

入会資格・条件に不適合となった場合

当クラブが閉鎖された場合

第12条（退会）

退会を希望する方は、退会希望月の前月10日までに所定の手続を行い、会社の承認を得て、その月の1日付で退会するものとします。

退会手続は、原則として会員が直接行うものとし、代理人による手続や電話、ファックス、郵便、電子メール等による手続はできません。

会費等の未納がある場合、これを完納するものとします。

会員証は、当クラブの最終利用時に返却するものとします。

当クラブの利用なく退会する場合や月途中に退会する場合においても、会費等の返還は一切ありません。

会社は退会手続がなされない限り、会費を請求します。

第13条（レッスン）

毎月のレッスンは、当クラブが定めるレッスンカレンダーに基づいて行うものとします。内容については別に定めます。

毎月必ず1回は、各級の合格基準に達しているかを確認する「見極め（進級テスト）」を行います。これに合格した会員は、次回のレッスンから進級します。なお、「見極め（進級テスト）」は定められた週に行いますが、コーチが進級項目を満たすために必要な泳力を有すると判断した場合は、通常レッスン時でも適宜個別に「見極め（進級テスト）」を行い、これに合格した会員は、次回レッスンから進級します。

会員は、レッスンを受講するにあたっては当クラブが指定する用品を使用するものとします。指定用品については別に定めます。

レッスンについて変更を希望する場合は、変更希望月の前月20日までに所定の手続を行い、会社の承認を得て、その月の1回目のレッスン日より変更するものとします。

会員にインフルエンザ、プール熱などの感染症や水いぼ、とびひなどの伝染性疾患と思われる症状が認められた場合、当クラブの判断によりプールへの入水を一時的に禁止することができるものとします。

第14条（ルネッスキッズ安心システム）

会員は、入会と同時に必ず「ルネッスキッズ安心システム」への登録を行うものとします。

連絡事項や通知等が必要な場合、会社は原則として「ルネッスキッズ安心システム」を利用して行うものとします。

第15条（欠席）

会員はレッスンを欠席する場合、「ルネッスキッズ安心システム」より連絡を行うものとします。

欠席の連絡は、原則としてレッスン開始2時間前までに行うものとします。

第16条（振替）

会員がレッスンを欠席した場合には、振替受講の権利（振替権利）が発生します。

振替受講は、当クラブにて決められた曜日・日時で行います。

振替受講が可能な曜日・日時には、振替権利の回数分だけ参加できます。

振替受講の申し込みは、事前に専用アプリから行う必要があります。

振替ルールの変更が生じた場合は、事前に通知します。

第17条（スクールバス）

会員は、所定の手続を行うことによりスクールバスを利用することができます。詳細は「ルネッス　スクールバス利用規則」に定めます。

第18条（除名）

会社は、会員が次の各号に該当すると認められる場合、その会員を除名とします。

本規約に違反した場合

当クラブの定めるルール、マナー等を注意しても遵守しない場合

他の会員の迷惑となる行為をしたと会社が認めた場合

当クラブの運営に支障を与えまたは与える恐れがあると会社が判断した場合

会費の支払いを2ヶ月滞納した場合

当クラブの施設、設備等を故意に破損した場合

その他会社が除名を妥当と認めた場合

第19条（会社の免責）

会員が本クラブ内および駐車場内で生じた人的、物的事故並びに盗難、紛失、その他の事故については、会社の故意または重大な過失がある場合を除き、会社は一切の損害賠償の責を負いません。

第20条（紛失物、忘れ物等）

会員が当クラブの利用に際して生じた紛失物、忘れ物および放置物等については、会社は一切の損害賠償、補償等の責を負わないものとします。

当クラブ内での忘れ物、放置物については、原則として2週間保管した後に処分します。

第21条（会員の損害賠償責任）

会員は、当クラブ施設の利用中、自己の責に帰すべき事由により、会社または第三者に損害を与えた場合、速やかにその賠償の責に任ずるものとします。

第22条（注意事項）

会員は下記の事項を遵守するものとします。

持ち物にはすべてはっきりと名前を書くこと

貴重品やゲーム機などレッスンに不要なものは持ち込まないこと

レッスンの様子を撮影することは妨げませんが、ソーシャルネットワーキングサービスなどを利用する際は節度をもって利用すること

第23条（営業日、利用制限等）

当クラブの営業日、営業時間については別に定めます。

会社は、当クラブの運営管理上、営業日および営業時間を変更する場合があります。

会社は、次の各号の場合、当クラブ施設の全部または一部を臨時に閉鎖もしくは利用制限をする場合があります。

天災、天候、災害、事故、緊急事態の発生等施設利用が適切でない場合

施設の点検、修理または改装工事等が必要な場合

法令、行政指導による場合

会員の安全確保において当クラブが必要と判断した場合

その他、会社が運営管理上必要と認めた場合

営業日、営業時間の変更がある場合は、施設内への掲示または「ルネッスキッズ安心システム」を利用して、会員に連絡・通知します。

第24条（個人情報の取扱）

当クラブは、会員に対し必要な個人情報を取得するものとします。

会員は、下記の目的で個人情報を利用することを承諾するものとします。

会費を徴収する目的

当社の商品・サービスを改善する目的

会員は、下記の通り個人情報を当クラブ内へ掲示することを承諾するものとします。

会員名簿、進級表、タイム結果の掲示

当クラブ会報等への名前・写真の掲示

第25条（規約の改正）

この規約は必要に応じて改正される場合があります。





西暦2024年5月21日改定 西暦2024年6月1日施行`,



}