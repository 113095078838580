<template>
    <section class="nyukai-main">
        <v-container class="fill-height" fluid>
   
            <v-row class="nyukai-main__contents" v-if="!$store.state.isComplete">
                <v-col cols="12" sm="8" md="4" class="nyukai-main__contents-text">
                    <v-card flat color="transparent">


                        <!-- step1 ご利用情報入力 -->
                        <v-form v-model="valid" ref="reservationForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼入会資格について</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm1">
                                        <v-checkbox v-model="ruleForm.confirm1" :rules="rules.confirm1">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm1}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm2">
                                        <v-checkbox v-model="ruleForm.confirm2" :rules="rules.confirm2" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm2}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm3">
                                        <v-checkbox v-model="ruleForm.confirm3" :rules="rules.confirm3" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm3}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm4">
                                        <v-checkbox v-model="ruleForm.confirm4" :rules="rules.confirm4" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm4}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm5">
                                        <v-checkbox v-model="ruleForm.confirm5" :rules="rules.confirm5">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm5}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼受講の際の健康確認について</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm6">
                                        <v-checkbox v-model="ruleForm.confirm6" :rules="rules.confirm6" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm6}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼月会費と年会費のお支払いについて</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm7">
                                        <v-checkbox v-model="ruleForm.confirm7" :rules="rules.confirm7">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm7}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm8">
                                        <v-checkbox v-model="ruleForm.confirm8" :rules="rules.confirm8">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm8}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm9">
                                        <v-checkbox v-model="ruleForm.confirm9" :rules="rules.confirm9">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm9}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm10">
                                        <v-checkbox v-model="ruleForm.confirm10" :rules="rules.confirm10" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm10}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼退会手続きについて</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm11">
                                        <v-checkbox v-model="ruleForm.confirm11" :rules="rules.confirm11">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm11}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼レッスンについて</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm12">
                                        <v-checkbox v-model="ruleForm.confirm12" :rules="rules.confirm12">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm12}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm13">
                                        <v-checkbox v-model="ruleForm.confirm13" :rules="rules.confirm13">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm13}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼欠席等の連絡について</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm14">
                                        <v-checkbox v-model="ruleForm.confirm14" :rules="rules.confirm14" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm14}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm15">
                                        <v-checkbox v-model="ruleForm.confirm15" :rules="rules.confirm15" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm15}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm16">
                                        <v-checkbox v-model="ruleForm.confirm16" :rules="rules.confirm16" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm16}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼その他</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm17">
                                        <v-checkbox v-model="ruleForm.confirm17" :rules="rules.confirm17" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm17_1}}
                                                <br />
                                                {{confirmDocument.confirm17_2}}
                                                <br />
                                                {{confirmDocument.confirm17_3}}
                                                <br />
                                                {{confirmDocument.confirm17_4}}
                                                <br />
                                                {{confirmDocument.confirm17_5}}
                                                <br />
                                                {{confirmDocument.confirm17_6}}

                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼個人情報の管理について</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm18">
                                        <v-checkbox v-model="ruleForm.confirm18" :rules="rules.confirm18" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm18}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼個人情報の管理について</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm19">
                                        <v-checkbox v-model="ruleForm.confirm19" :rules="rules.confirm19" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm19}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-confirm20">
                                        <v-checkbox v-model="ruleForm.confirm20" :rules="rules.confirm20" >
                                            <template v-slot:label>
                                                {{confirmDocument.confirm20}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" id="ruleForm-confirm21">
                                        <v-checkbox v-model="ruleForm.confirm21" :rules="rules.confirm21">
                                            <template v-slot:label>
                                                {{confirmDocument.confirm21}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">▼利用規約</p>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-terms">
                                        <textarea style="width:100%;height:200px; border:solid 1px #000;" readonly>{{confirmDocument.termsDocument}}</textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-checkbox v-model="ruleForm.terms" :rules="rules.terms" >
                                            <template v-slot:label>
                                                {{confirmDocument.terms}}
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" style="text-align:center;">
                                        <v-btn depressed @click="moveNextForm('payment')" style="margin-right:5%;" color="primary">合計金額確認</v-btn>
                                        <v-btn depressed @click="returnForm" color="warning">戻る</v-btn>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>

                    </v-card>
                </v-col>

            </v-row>
        </v-container>

    </section>
</template>

<script>
    import ConfirmDocument from '../ConfirmDocuments'

    export default {

        components: {
        },
        model: {
            prop: 'ruleForm',
            event: 'changeFormValue'
        },
        props: {
            ruleForm: Object,
        },

        data() {
            return {
                //バリデーションに引っかかった時にヘッダーの分も上にしないと見えないため(createdで高さ取得)
                headerHeight: 0,
                valid: true,
                confirmDocument: {
                    confirm1: ConfirmDocument.confirm1,
                    confirm2: ConfirmDocument.confirm2,
                    confirm3: ConfirmDocument.confirm3,
                    confirm4: ConfirmDocument.confirm4,
                    confirm5: ConfirmDocument.confirm5,
                    confirm6: ConfirmDocument.confirm6,
                    confirm7: ConfirmDocument.confirm7,
                    confirm8: ConfirmDocument.confirm8,
                    confirm9: ConfirmDocument.confirm9,
                    confirm10: ConfirmDocument.confirm10,
                    confirm11: ConfirmDocument.confirm11,
                    confirm12: ConfirmDocument.confirm12,
                    confirm13: ConfirmDocument.confirm13,
                    confirm14: ConfirmDocument.confirm14,
                    confirm15: ConfirmDocument.confirm15,
                    confirm16: ConfirmDocument.confirm16,
                    confirm17_1: ConfirmDocument.confirm17_1,
                    confirm17_2: ConfirmDocument.confirm17_2,
                    confirm17_3: ConfirmDocument.confirm17_3,
                    confirm17_4: ConfirmDocument.confirm17_4,
                    confirm17_5: ConfirmDocument.confirm17_5,
                    confirm17_6: ConfirmDocument.confirm17_6,
                    confirm18: ConfirmDocument.confirm18,
                    confirm19: ConfirmDocument.confirm19,
                    confirm20: ConfirmDocument.confirm20,
                    confirm21: ConfirmDocument.confirm21,
                    terms: ConfirmDocument.terms,
                    termsDocument: ConfirmDocument.termsDocument,
                },
                rules: {
                    confirm1: [v => { return this.blankRules(v, 0) },],
                    confirm2: [v => { return this.blankRules(v, 1) },],
                    confirm3: [v => { return this.blankRules(v, 2) },],
                    confirm4: [v => { return this.blankRules(v, 3) },],
                    confirm5: [v => { return this.blankRules(v, 4) },],
                    confirm6: [v => { return this.blankRules(v, 5) },],
                    confirm7: [v => { return this.blankRules(v, 6) },],
                    confirm8: [v => { return this.blankRules(v, 7) },],
                    confirm9: [v => { return this.blankRules(v, 8) },],
                    confirm10: [v => { return this.blankRules(v, 9) },],
                    confirm11: [v => { return this.blankRules(v, 10) },],
                    confirm12: [v => { return this.blankRules(v, 11) },],
                    confirm13: [v => { return this.blankRules(v, 12) },],
                    confirm14: [v => { return this.blankRules(v, 13) },],
                    confirm15: [v => { return this.blankRules(v, 14) },],
                    confirm16: [v => { return this.blankRules(v, 15) },],
                    confirm17: [v => { return this.blankRules(v, 16) },],
                    confirm18: [v => { return this.blankRules(v, 17) },],
                    confirm19: [v => { return this.blankRules(v, 18) },],
                    confirm20: [v => { return this.blankRules(v, 19) },],
                    confirm21: [v => { return this.blankRules(v, 20) },],
                    terms: [v => { return this.blankRules(v, 21) },],
 
                },
                //全体をバリデーションした時にエラーが起こった一番上の項目へ飛ばすためのオブジェクト
                validationResult: [
                    { id: "ruleForm-confirm1", result: true },
                    { id: "ruleForm-confirm2", result: true },
                    { id: "ruleForm-confirm3", result: true },
                    { id: "ruleForm-confirm4", result: true },
                    { id: "ruleForm-confirm5", result: true },
                    { id: "ruleForm-confirm6", result: true },
                    { id: "ruleForm-confirm7", result: true },
                    { id: "ruleForm-confirm8", result: true },
                    { id: "ruleForm-confirm9", result: true },
                    { id: "ruleForm-confirm10", result: true },
                    { id: "ruleForm-confirm11", result: true },
                    { id: "ruleForm-confirm12", result: true },
                    { id: "ruleForm-confirm13", result: true },
                    { id: "ruleForm-confirm14", result: true },
                    { id: "ruleForm-confirm15", result: true },
                    { id: "ruleForm-confirm16", result: true },
                    { id: "ruleForm-confirm17", result: true },
                    { id: "ruleForm-confirm18", result: true },
                    { id: "ruleForm-confirm19", result: true },
                    { id: "ruleForm-confirm20", result: true },
                    { id: "ruleForm-confirm21", result: true },
                    { id: "ruleForm-terms", result: true },
                ],
                //validate: [],
                blankRules: function (v, index) { return v || this.changeValidationResult(index) || '必須です' },
   

            }
        },

        methods: {
            applyNewly: function () {
                window.open(this.$store.state.projectStarturl);
            },
            //全体をバリデーションした時にどこがバリデーションクリアしないかを区別させる関数:validationResultにfalseを入れる
            changeValidationResult: function (index) {
                this.validationResult[index].result = false;
                //vuetifyのruleの仕様上return falseをしてその右の文字列まで評価させる必要がある
                return false;
            },
            //バリデーション通過しなかった時にその中でどの項目が一番上か、idを取ってくる処理
            getFalseValidationID: function () {
                var targetIndex = this.validationResult.length - 1;
                //配列の末尾から見て一番先頭の所をターゲットにする
                for (var i = this.validationResult.length - 1; i >= 0; i--) {
                    if (!this.validationResult[i].result) targetIndex = i;
                }
                return this.validationResult[targetIndex].id;
            },
            resetValidationResult: function () {
                //一度全部trueにする
                for (var i in this.validationResult) {
                    this.validationResult[i].result = true;
                }
            },
            moveNextForm: function (pageName) {

                this.resetValidationResult();

                if (this.$refs.reservationForm.validate()) {

                    this.$emit('moveForm', pageName);

                } else {

                    alert("未入力の項目があります");

                    //#で場所に移動し、ヘッダーの分だけ下に下げる
                    //window.location.href = '#' + this.getFalseValidationID();
                    var element = document.getElementById(this.getFalseValidationID())
                    var scroll = window.pageYOffset + (element.getBoundingClientRect().top - element.clientHeight)
                    window.scrollTo(0, scroll);
                }

            },
            returnForm: function () {
                this.$emit('moveForm', "customer");
            },
        },

        created() {

            console.log(this.ruleForm);

        },
        mounted: function () {

            this.headerHeight = document.getElementById("RenaissHeader").clientHeight;

            window.scrollTo(0, 0);


        },
        watch: {
            ruleForm: {
                handler: function () {
                    //親の値と同期をする
                    this.$emit('changeFormValue', this.ruleForm);
                },
                deep: true,
            },

        }
   


    }
</script>

<style lang="scss" scoped>
    .usedetail_text {
        text-align: left;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 0;
    }

    .nyukai-main__contents {
        justify-content: center;
    }

    .nyukai-radio {
        margin-top: 10px;

        @media only screen and (min-width:651px) {
            margin-top: 0px;
        }

        @media only screen and (min-width:961px) {
            margin-top: 0px;
        }
    }

    .confirm_checkbox {
        margin-bottom: 3%;
    }
</style>
